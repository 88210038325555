@if (advisorMessageForm) {
  <section class="flex flex-col gap-y-30">
    <p class="text-lg">Please review your request below and make sure all the information is accurate.</p>
    <div class="flex flex-col gap-y-10">
      <div class="flex flex-col gap-y-5">
        <span>Message:</span>
        @if (reviewModel?.notes && reviewModel?.notes?.length) {
          <div class="flex flex-col p-10 rounded md:p-20 bg-gray-lightest">
            <span class="mb-10 whitespace-pre-wrap overflow-wrap-text text-gray-dark">{{reviewModel?.notes}}</span>
          </div>
        } @else{
          <em class="text-sm text-gray-light">No message</em>
        }
      </div>
      <span class="divider-horizontal my-20"></span>
    </div>
    <div class="flex flex-col gap-y-5">
      <span>Files:</span>
      @if (reviewModel?.files && reviewModel?.files?.length) {
        @for (item of reviewModel?.files; track item) {
          <div class="flex flex-col gap-y-5" class="font-bold">
            {{item.name}}
          </div>
        }
      } @else{
        <em class="text-sm text-gray-light">No files</em>
      }
    </div>
  </section>
}